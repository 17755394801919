export enum AbtestStatusTabs {
  ALL = 'all',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  STOPPED = 'stopped',
  ERROR = 'error',
  TRANSIT = 'transit',
  DRAFT = 'draft',
  ARCHIVE = 'archive',
}

export enum CampaignStatusFilters {
  ALL_ACTIVE = 'all_active',
  READY = 'ready',
  ARCHIVE = 'archive',
  ACTIVE = 'active',
  PAUSE = 'pause',
  CREATED = 'created',
}

export enum TimeZoneTypes {
  EVERYDAY = 'everyday',
  WEEKENDS = 'weekends',
  WEEKDAYS = 'weekdays',
  CUSTOM = 'custom',
}

export enum Weekdays {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
}

// export enum SkedIntervalTypes {
//   SUNDAY = 'sunday',
//   MONDAY = 'monday',
//   TUESDAY = 'tuesday',
//   WEDNESDAY = 'wednesday',
//   THURSDAY = 'thursday',
//   FRIDAY = 'friday',
//   SATURDAY = 'saturday',
//   EVERYDAY = 'everyday',
//   WEEKENDS = 'weekends',
//   WEEKDAYS = 'weekdays',
// }

export enum AnalyticsEvents {
  // search
  ADD_PLUS_FIXED_SEARCH_KEY_PRESS = 'add_plus_fixed_search_key_press',
  DELETE_PLUS_FIXED_SEARCH_KEY_PRESS = 'delete_plus_fixed_search_key_press',
  ADD_MINUS_SEARCH_SEARCH_KEY_PRESS = 'add_minus_search_search_key_press',
  DELETE_MINUS_SEARCH_SEARCH_KEY_PRESS = 'delete_minus_search_search_key_press',
  ADD_PLUS_EXACT_SEARCH_KEY_PRESS = 'add_plus_exact_search_key_press',
  DELETE_PLUS_EXACT_SEARCH_KEY_PRESS = 'delete_plus_exact_search_key_press',
  SET_CROSSMINUSATION_SEARCH_KEY_PRESS = 'set_crossminusation_search_key_press',

  // auto
  ADD_MINUS_SEARCH_AUTO_KEY_PRESS = 'add_minus_search_auto_key_press',
  DELETE_MINUS_SEARCH_AUTO_KEY_PRESS = 'delete_minus_search_auto_key_press',
  ADD_PLUS_EXACT_AUTO_KEY_PRESS = 'add_plus_exact_auto_key_press',
  DELETE_PLUS_EXACT_AUTO_KEY_PRESS = 'delete_plus_exact_auto_key_press',
  SET_CROSSMINUSATION_AUTO_KEY_PRESS = 'set_crossminusation_auto_key_press',

  // phrase_folder
  ADD_FOLDER_PHRASES_KEY_PRESS = 'add_folder_phrases_key_press',
  DELETE_FOLDER_PHRASES_KEY_PRESS = 'delete_folder_phrases_key_press',
  RENAME_FOLDER_PHRASES_KEY_PRESS = 'rename_folder_phrases_key_press',
  DUPLICATE_FOLDER_PHRASES_KEY_PRESS = 'duplicate_folder_phrases_key_press',
  MOVE_FOLDER_PHRASES_KEY_PRESS = 'move_folder_phrases_key_press',

  // campaign_folder
  ADD_FOLDER_CAMPAIGN_KEY_PRESS = 'add_folder_campaign_key_press',
  DELETE_FOLDER_CAMPAIGN_KEY_PRESS = 'delete_folder_campaign_key_press',
  RENAME_FOLDER_CAMPAIGN_KEY_PRESS = 'rename_folder_campaign_key_press',
  DUPLICATE_FOLDER_CAMPAIGN_KEY_PRESS = 'duplicate_folder_campaign_key_press',
  MOVE_FOLDER_CAMPAIGN_KEY_PRESS = 'move_folder_campaign_key_press',

  // export
  EXPORT_PHRASES_KEY_PRESS = 'export_phrases_key_press',
  EXPORT_STATISTICS_KEY_PRESS = 'export_statistics_key_press',

  // budget
  UPDATE_BUDGET_KEY_PRESS = 'update_budget_key_press',
  ADD_BUDGET_KEY_PRESS = 'add_budget_key_press',

  // campaign create/parser
  CREACTE_CAMPAIGNS_KEY_PRESS = 'creacte_campaigns_key_press',
  PARSER_CAMPAIGN_KEY_PRESS = 'parser_campaign_key_press',

  // status campaign
  START_CAMPAIGN_KEY_PRESS = 'start_campaign_key_press',
  PAUSE_CAMPAIGN_KEY_PRESS = 'pause_campaign_key_press',
  ARCHIVE_CAMPAIGN_KEY_PRESS = 'archive_campaign_key_press',
  DELETE_CAMPAIGN_KEY_PRESS = 'delete_campaign_key_press',

  // other
  SIGN_UP = 'sign_up',
  BIDDER_PAGE_OPEN = 'bidder_page_open',

  // search
  EXTENSION_ADD_PLUS_FIXED_SEARCH_KEY_PRESS = 'extension_add_plus_fixed_search_key_press',
  EXTENSION_DELETE_PLUS_FIXED_SEARCH_KEY_PRESS = 'extension_delete_plus_fixed_search_key_press',
  EXTENSION_ADD_MINUS_SEARCH_SEARCH_KEY_PRESS = 'extension_add_minus_search_search_key_press',
  EXTENSION_DELETE_MINUS_SEARCH_SEARCH_KEY_PRESS = 'extension_delete_minus_search_search_key_press',
  EXTENSION_ADD_PLUS_EXACT_SEARCH_KEY_PRESS = 'extension_add_plus_exact_search_key_press',
  EXTENSION_DELETE_PLUS_EXACT_SEARCH_KEY_PRESS = 'extension_delete_plus_exact_search_key_press',
  EXTENSION_SET_CROSSMINUSATION_SEARCH_KEY_PRESS = 'extension_set_crossminusation_search_key_press',

  // auto
  EXTENSION_ADD_MINUS_SEARCH_AUTO_KEY_PRESS = 'extension_add_minus_search_auto_key_press',
  EXTENSION_DELETE_MINUS_SEARCH_AUTO_KEY_PRESS = 'extension_delete_minus_search_auto_key_press',
  EXTENSION_ADD_PLUS_EXACT_AUTO_KEY_PRESS = 'extension_add_plus_exact_auto_key_press',
  EXTENSION_DELETE_PLUS_EXACT_AUTO_KEY_PRESS = 'extension_delete_plus_exact_auto_key_press',
  EXTENSION_SET_CROSSMINUSATION_AUTO_KEY_PRESS = 'extension_set_crossminusation_auto_key_press',

  // phrase_folder
  EXTENSION_ADD_FOLDER_PHRASES_KEY_PRESS = 'extension_add_folder_phrases_key_press',
  EXTENSION_DELETE_FOLDER_PHRASES_KEY_PRESS = 'extension_delete_folder_phrases_key_press',
  EXTENSION_RENAME_FOLDER_PHRASES_KEY_PRESS = 'extension_rename_folder_phrases_key_press',
  EXTENSION_DUPLICATE_FOLDER_PHRASES_KEY_PRESS = 'extension_duplicate_folder_phrases_key_press',
  EXTENSION_MOVE_FOLDER_PHRASES_KEY_PRESS = 'extension_move_folder_phrases_key_press',

  // campaign_folder
  EXTENSION_ADD_FOLDER_CAMPAIGN_KEY_PRESS = 'extension_add_folder_campaign_key_press',
  EXTENSION_DELETE_FOLDER_CAMPAIGN_KEY_PRESS = 'extension_delete_folder_campaign_key_press',
  EXTENSION_RENAME_FOLDER_CAMPAIGN_KEY_PRESS = 'extension_rename_folder_campaign_key_press',
  EXTENSION_DUPLICATE_FOLDER_CAMPAIGN_KEY_PRESS = 'extension_duplicate_folder_campaign_key_press',
  EXTENSION_MOVE_FOLDER_CAMPAIGN_KEY_PRESS = 'extension_move_folder_campaign_key_press',

  // export
  EXTENSION_EXPORT_PHRASES_KEY_PRESS = 'extension_export_phrases_key_press',
  EXTENSION_EXPORT_STATISTICS_KEY_PRESS = 'extension_export_statistics_key_press',

  // budget
  EXTENSION_UPDATE_BUDGET_KEY_PRESS = 'extension_update_budget_key_press',
  EXTENSION_ADD_BUDGET_KEY_PRESS = 'extension_add_budget_key_press',

  // campaign create/parser
  EXTENSION_CREACTE_CAMPAIGNS_KEY_PRESS = 'extension_creacte_campaigns_key_press',
  EXTENSION_PARSER_CAMPAIGN_KEY_PRESS = 'extension_parser_campaign_key_press',

  // status campaign
  EXTENSION_START_CAMPAIGN_KEY_PRESS = 'extension_start_campaign_key_press',
  EXTENSION_PAUSE_CAMPAIGN_KEY_PRESS = 'extension_pause_campaign_key_press',
  EXTENSION_ARCHIVE_CAMPAIGN_KEY_PRESS = 'extension_archive_campaign_key_press',
  EXTENSION_DELETE_CAMPAIGN_KEY_PRESS = 'extension_delete_campaign_key_press',

  // other
  EXTENSION_BIDDER_PAGE_OPEN = 'extension_bidder_page_open',

  // abtest
  ABTESTS_PAGE_ENTRY = 'abtests_page_entry',
  ABTESTS_ARTICLE_SELECTION = 'abtests_article_selection',
  ABTESTS_TEST_SELECTION = 'abtests_test_selection',
  ABTESTS_TEST_CREATION = 'abtests_test_creation',
  ABTESTS_CAMPAIGN_SELECTION = 'abtests_campaign_selection',
  ABTESTS_STATUS_CHANGE_IN_HEADER = 'abtests_status_change_in_header',
  ABTESTS_BUDGET_UPDATE_IN_HEADER = 'abtests_budget_update_in_header',
  ABTESTS_BUDGET_DEPOSIT_IN_HEADER = 'abtests_budget_deposit_in_header',
  ABTESTS_SUCCESSFUL_PHOTO_UPLOAD = 'abtests_successful_photo_upload',
  ABTESTS_NUMBER_OF_PHOTOS_UPLOADED = 'abtests_number_of_photos_uploaded',
  ABTESTS_SET_SETTINGS = 'abtests_set_settings',
  ABTESTS_TEST_LAUNCH = 'abtests_test_launch',
  ABTESTS_TEST_FORCED_STOP = 'abtests_test_forced_stop',
  ABTESTS_SET_AS_MAIN = 'abtests_set_as_main',
}

export enum ProductTabs {
  CAMPAIGNS = 'campaigns',
  STATISTICS = 'statistics',
  JAM = 'jam',
  COMMENTS = 'comments',
  COMMON = 'common',
}

export enum CampaignTypesFilters {
  ALL = 'all',
  AUTO = 'auto',
  CATALOG = 'catalog',
  CAROUSEL = 'carousel',
  SEARCH = 'search',
  RECOM = 'recom',
  SEACAT = 'seacat',
}

export enum AdminTypeFilters {
  USERS = 'users',
  ACCOUNTS = 'accounts',
  EVENTS = 'events',
  ANALYTYCTS = 'analytycts',
}

export enum PhraseClusterTabFilters {
  CLUSTER = 'cluster',
  KEYWORDS = 'keywords',
  POOL = 'pool',
}

export enum CampaignAutoTypesFilters {
  AUTO_CAROUSEL = 'auto_carousel',
  AUTO_CATALOG = 'auto_catalog',
  AUTO_RECOM = 'auto_recom',
  AUTO_SEARCH = 'auto_search',
}

export enum CampaignSearchTypesFilters {
  SEARCH_SEARCH = 'search_search',
  SEARCH_CATALOG = 'search_catalog',
}

export enum PhraseFilters {
  ALL = 'all',
  MINUS_EXACT = 'minus_exact',
  MINUS_MATCH = 'minus_match',
  MINUS_SEARCH = 'minus_search',
  PLUS_EXACT = 'plus_exact',
  PLUS_FIXED = 'plus_fixed',
  PLUS_SEARCH = 'plus_search',
  STATS = 'stats',
  COMMON = 'common',
}

export enum PhraseTabFilters {
  TYPE_MINUS = 'minus',
  TYPE_MINUS_EXACT = 'minus_exact',
  TYPE_MINUS_MATCH = 'minus_match',
  TYPE_PLUS = 'plus',
  TYPE_EXACT = 'exact',
  TYPE_FIXED = 'fixed',
  STATS = 'stats',
}

export enum AutoPhraseTabFilters {
  ALL = 'all',
  PLUS_SEARCH = 'plus_search',
  PLUS_EXACT = 'plus_exact',
  MINUS = 'minus',
  STATS = 'stats',
  COMMON = 'common',
}

export enum AutoPhraseFilters {
  ALL_PHRASES = 'all_phrases',
  MINUS_SEARCH = 'minus_search',
  PLUS_EXACT = 'plus_exact',
  PLUS_SEARCH = 'plus_search',
}

export enum StatsFilters {
  ALL = 'all',
  SEARCH = 'search',
  OTHER = 'other',
  RECOM = 'recom',
  CATALOG = 'catalog',
  CONVERSION = 'conversion',
}

export enum PositionFilters {
  ALL = 'all',
  BECAME = 'became',
  BOOSTED = 'boosted',
  NO_BOOSTED = 'no_boosted',
  BOOSTED_TODAY = 'boosted_today',
  NO_BOOSTED_TODAY = 'no_boosted_today',
  BECAME_TODAY = 'became_today',
  FOUND = 'found',
  NOT_FOUND = 'not_found',
  OUT = 'out',
  OUT_TODAY = 'out_today',
  BOOST_ADVANTAGE = 'boost_advantage',
  PLUS_MINUS = 'plus_minus',
}

export enum TopPositionFilters {
  ALL = 'all',
  TOP_1 = 'top_1',
  TOP_5 = 'top_5',
  TOP_10 = 'top_10',
  TOP_50 = 'top_50',
  TOP_100 = 'top_100',
}

export enum ProductsSortTypes {
  DEFAULT = '',
  NAME = 'name',
  VIEW_COUNT = 'view_count',
  CLICK_COUNT = 'click_count',
  CTR = 'CTR',
  CPC = 'CPC',
  SPENT = 'spent',
}

export enum BoostAdvantages {
  NONE = 'none',
  SEACAT = 'seacat',
  AUTO = 'auto',
}

export enum SortDirections {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TokenScopeIds {
  CONTENT = 2,
  ANALYTICS = 4,
  PRICES_AND_DISCOUNTS = 8,
  MARKETPLACE = 16,
  STATISTICS = 32,
  PROMOTION = 64,
  QUESTIONS_AND_FEEDBACKS = 128,
  RECOMMENDATIONS = 256,
}

export enum ParseMethods {
  AUTO = 0,
  BULK = 1,
  SINGLE = 2,
}

export enum StatsChartTypes {
  line = 'line',
  bar = 'bar',
}

export enum StatsChartAxisTypes {
  linear = 'linear',
  log = 'log',
}

export enum StatsChartPeriodTypes {
  DAILY = 'daily',
  HOUR_BY_DAY_SUMMARY = 'hour_by_day_summary',
}

export enum DateIntervals {
  today = 'today',
  yesterday = 'yesterday',
  beforeYesterday = 'before_yesterday',
  week = 'week',
  month = 'month',
  all = 'all',
  other = 'other',
}

export enum StatisticColumnKeys {
  date = 'date',
  clickCount = 'clickCount',
  cpc = 'cpc',
  cpm = 'cpm',
  cr = 'cr',
  ctr = 'ctr',
  orderCount = 'orderCount',
  spent = 'spent',
  viewCount = 'viewCount',
}

export enum PhraseStatuses {
  minus = 'minus',
  plus = 'plus',
  fixed = 'fixed',
  minus_exact = 'minus_exact',
  minus_match = 'minus_match',
  plus_search = 'plus_search',
}

// TODO переделать на snakeCase
export enum StatsLines {
  clickCount = 'clickCount',
  cpc = 'cpc',
  cpm = 'cpm',
  cr = 'cr',
  cro = 'cro',
  cpo = 'cpo',
  drr = 'drr',
  crl = 'crl',
  cpl = 'cpl',
  ctr = 'ctr',
  orderCount = 'orderCount',
  orderSum = 'orderSum',
  spent = 'spent',
  viewCount = 'viewCount',
  jamClickCount = 'jamClickCount',
  cpcJam = 'cpcJam',
  goodsCount = 'goodsCount',
  cartCount = 'cartCount',
}

export enum HistoryTabs {
  CAMPAIGN = 'campaign',
  CPM = 'cpm',
  BUDGET = 'budget',
  BID = 'bid',
}

export enum CampaignLogTypes {
  CAMPAIGN_CHANGED_STATUS = 'campaign_changed_status',
  CAMPAIGN_CHANGED_CPM = 'campaign_changed_cpm',
  CAMPAIGN_CHANGED_AUTO_DEPOSIT = 'campaign_changed_auto_deposit',
  CAMPAIGN_DEPOSIT = 'campaign_deposit',
  CAMPAIGN_PRODUCT_CHANGED_USE_BID_MANAGER = 'campaign_product_changed_use_bid_manager',
  CAMPAIGN_PRODUCT_CHANGED_MAX_CPM = 'campaign_product_changed_max_cpm',
  CAMPAIGN_PRODUCT_CHANGED_TARGET_POSITION = 'campaign_product_changed_target_position',
}

export enum CampaignLogSources {
  MANUAL = 'manual',
  KM = 'km',
  SKED = 'sked',
  AUTO_DEPOSIT = 'auto_deposit',
  DAILY_BUDGET = 'daily_budget',
  WB_SYNC = 'wb_sync',
  BIDDER = 'bidder',
}

/** **************************************
 * Раздел: Наименования колонок
 ****************************************/

export enum ColumnHeader {
  NAME = 'Название товара',
  NAME_PRODUCT = 'Название',
  ARTICLE = 'Артикул',
  BRAND = 'Бренд',
  SUBJECT_NAME = 'Предмет',
  PRICE = 'Цена, ₽',
  RATING = 'Рейтинг',
  VIEW_COUNT = 'Показы',
  CLICK_COUNT = 'Клики',
  CTR = 'CTR, %',
  CPC = 'CPC, ₽',
  CPC_JAM = 'CPC джем, ₽',
  CPM = 'CPM, ₽',
  CPM_MID = 'CPM средний, ₽',
  CR = 'CR, %',
  CART_COUNT = 'Корзина, шт.',
  ORDER_COUNT = 'Заказы, шт.',
  ORDER_SUM = 'Заказы, ₽',
  GOODS_COUNT = 'Товары, шт.',
  DRR = 'ДРР, %',
  CPO = 'CPO, ₽',
  CPO_JAM = 'CPO джем, ₽',
  SPENT_SCHEDULE = 'Динамика Затраты, ₽',
  TREND_SCHEDULE = 'Динамика за 15 дней',
  SPENT = 'Затраты, ₽',
  CRL = 'CRL, %',
  CRO = 'CRO, %',
  CPL = 'CPL, ₽',
  SELL_COUNT = 'Продажи, шт.',
  SELL_SUM = 'Продажи, ₽',
  CRS = 'Выкуп, %',
  CAMPAIGN_NAME = 'Название кампании',
  CAMPAIGN_WB_ID = 'ID кампании',
  STATUS = 'Статус',
  TYPE = 'Тип',
  USE_BID_MANAGER = 'Биддер',
  AUTO_DEPOSTE = 'Автопополнение',
  BUDGET = 'Бюджет, ₽',
  STARTED_AT = 'Дата создания',
  STATUS_PK = 'PK',
  FIX_PHRASES = 'Фиксированные фразы',
  INDICATORS = 'Показатель',
  ID = 'ID',
  REQUEST_COUNT = 'Частота запроса',
  STOPPED = 'Приостановлено',
  DATE = 'Дата',
  PHRASES = 'Фразы',
  COUNT = 'Частота',
  PRIORITY = 'Приоритет',
  BOOST = 'Буст',
  POOL_HASH = 'Poolhash',
  PRESET = 'Пресет',
  CLASTER = 'Кластер',
  IMG = 'Изображение',
  CARDS = 'Карточек',
  CLASTER_WB = 'Кластер WB',
  PHRASE_COUNT = 'Кол-во фраз',
  CATEGORY = 'Категория',
  TOP = 'Топ',
  DELTA = 'Дельта',
  DELTA_TOP = 'Дельта ТОПа',
  HOUR = 'Часы',
  PRODUCT = 'Товар',
  TOTAL = 'За выбранный период',
  HISTORY_CPM_SOURCE = 'Источник изменений',
  HISTORY_CPM_OLD = 'Было, ₽',
  HISTORY_CPM_NEW = 'Стало, ₽',
  HISTORY_CPM_DIFF = 'Изменение, ₽',
  HISTORY_BOOST_POSITION = 'Место после буста',
  HISTORY_BUDGET_DEPOSITE_TYPE = 'Источник средств',
  HISTORY_BUDGET_TYPE = 'Тип пополнения',
  HISTORY_BUDGET_SUM = 'Сумма, ₽',
  HISTORY_CAMPAIGN_ACTION = 'Действие с кампанией',
  HISTORY_BID_ACTION = 'Действие с Биддером',
  HISTORY_ALL_ACTION = 'Вид события',
  HISTORY_ALL_TYPE = 'Тип события',
  HISTORY_ALL_COMMENT = 'Комментарий',
  HISTORY_AUTO_SETTINGS_CHANGE = 'Подробности',
  JAM_CLICK_COUNT = 'Клики джем',
  JAM_CART_COUNT = 'Корзина джем',
  JAM_ORDER_COUNT = 'Заказы джем',
  TAG = 'Тег',
  FBS_ORDER_COUNT = 'FBS Заказы за 7 дней, шт',
  FBS_ORDER_SUMM = 'FBS Заказы за 7 дней, руб',
  FBO_ORDER_COUNT = 'FBO Заказы за 7 дней, шт',
  FBO_ORDER_SUMM = 'FBO Заказы за 7 дней, руб',
  ORDER_SEVEN_COUNT = 'Заказы за 7 дней, шт',
  ORDER_SEVEN_SUMM = 'Заказы за 7 дней, руб',
}

export enum RowHeaders {
  SEARCH = 'Поиск',
  CATALOG = 'Каталог',
  CAROUSEL = 'Полки',
  CONVERSION = 'Ассоциированные',
}
