export function filterBySearchTiles(
  searchTiles: string[],
  filterValue: string,
  minus?: boolean,
): boolean {
  // Функция для экранирования специальных символов в регулярных выражениях
  const escapeRegExp = (str: string): string => str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

  return searchTiles.every((searchTile) => {
    try {
      const escapedTile = escapeRegExp(searchTile);
      const isMatch = new RegExp(escapedTile, 'i').test(filterValue);

      return minus ? !isMatch : isMatch;
    } catch (error) {
      console.error(`Ошибка в filterBySearchTiles при обработке: ${searchTile}`, error);

      return false;
    }
  });
}
